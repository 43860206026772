export const config = {
    paths : {
        frontUrl: 'https://uk.lighthub.law/',
        apiBaseUrl: 'https://uk.lighthub.law/api/v1/',
        serverPublicUrl: 'https://uk.lighthub.law/api/public/', 
    },
    keys:{
        azureClient : '71d3d7a2-14ce-457e-a035-86d20fb65b4b',
        cexClient : '140d879d-49dc-4be9-91f9-0b79b14ebff3',
        stripePK : 'pk_test_51KdXhBCLthOerQnbI5Q7p6kx2RpJFndamgLe1DFQFeyxPKvq6NUvbtNC4C4Jm9wfkFevN4FaYN1vDIrO8AQwLowi00aOSug6PY'
    }
}